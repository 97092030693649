.banner{ background-size: cover; height: 600px; color: #fff;}
.content{ padding-top: 220px; height: 190px; margin-left: 50px;}
.title{ font-size: 3rem; font-weight: 800; margin-bottom: 2.3rem;}
.button{ color: #fff; outline: none; border: none; font-weight: 700; border-radius: 5px; padding-left: 2rem; padding-right: 2rem; padding-top: 0.7rem; padding-bottom: 0.7rem; background-color: rgba(51, 51, 51, 0.5); cursor: pointer; margin-right: 1rem;}
.button:hover{ color: black; background-color: #e6e6e6;}
.description { width: 50rem; line-height: 1.3; padding-top: 1rem; font-size: 1rem; height: 80px; max-width: 600px;}
.fade-bottom{ height: 12rem; background-image: linear-gradient(180deg,transparent,rgba(37,37,37,0.61),#111);}

@media (max-width: 768px) {
    .banner {
      height: 400px;
    }
  
    .content {
      padding-top: 120px;
      height: 140px;
      margin-left: 20px;
    }
  
    .title {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
  
    .button {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  
    .description {
      width: auto;
      font-size: 0.8rem;
      height: auto;
      max-width: 100%;
    }
  
    .fade-bottom {
      height: 8rem;
    }
  }
  