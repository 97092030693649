.footer-content{ color: #1b1b1b; background-color: #f9f9fb; width: 100%; height: 340px;}
.footer-nav { display: flex; justify-content: space-around; padding-top: 3rem;}
.nav-items a { font-weight: 200; padding-right: 1.5rem; cursor: pointer; border-right: 0.5px solid #1b1b1b; margin: 10px; font-size: 13px; text-decoration: none; color: #1b1b1b;}
.nav-items a:last-of-type {border-right: none;}
.nav-items a:hover {color: #020609;}
.social-linked { width: 36px; padding-top: 1.5rem; padding-left: 1.2rem;}
.social-fb {width: 33px; padding-top: 1.5rem; padding-left: 1.2rem;}
.brand-info{ display: flex;}
.footer-logo img { width: 70px; padding-top: 3rem; padding-left: 1.5rem;}
.copyright p{ padding-top: 4rem; padding-left: 1.5rem; max-width: 500px;font-weight: 100; font-size: 12px;}
.web-url a { font-size: 13px; margin-left: 1.5rem; text-decoration: none; color: #1b1b1b; font-weight: 200;}


@media (max-width: 768px) {
.footer-nav{ margin-left: 1rem;}

}